import * as React from 'react';
import { styled } from '@mui/material/styles';
import APP_HTTP from '../../../APP_HTTP.jsx';
import PropTypes from 'prop-types';
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: 'text.secondary' }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };
const StartNewSession = (props) => {
  let messages = [
    {key:'back_dues',value:'It will calculate the BACK DUES and will be assign to the respective student',is_checked:false,is_processed:false},
    {key:'fee_structure',value:'All student FEE STRUCTURE will be reset and will automatically assign as per their respective class fee',is_checked:false,is_processed:false},
    {key:'payment_sequence',value:'Payment SEQUENCE will be reset and starts to 1',is_checked:false,is_processed:false},
    {key:'student_attendance',value:'It will clear student ATTENDANCE HISTORY',is_checked:false,is_processed:false},
    {key:'homework',value:'It will clear assigned HOMEWORK HISTORY',is_checked:false,is_processed:false}
  ];
  const [message_list,setMessageList] = React.useState(messages);
  const [step,setStep] = React.useState(1);
  const [is_open,setIsOpen] = React.useState(false);
  const handleSelectItem = (index,checked) =>{
    let msglist = [...message_list];
    msglist[index].is_checked = checked;
    setMessageList(msglist);
  }
  const handleProceed = (st) =>{
    setStep(st);
  }
  const handleChangeCredentials = (key,value) =>{

  }
  const handleStartNewSession = () =>{

  }
  const handleClose = ()=>{
    setIsOpen(false);
  }
  const handleOpenSession = ()=>{
    setIsOpen(true);
  }
  return (
    <>
        <Button variant='contained' color='success' onClick={handleOpenSession}>START A NEW SESSION</Button>
        <Dialog open={is_open} >
            
            <DialogTitle>What will happen if you start a new session?</DialogTitle>
            
            <DialogContent>
                <Grid container spacing={2} className="mrtop-10 mrbtm-30">
                    {(step===1) &&
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            {(message_list.map((value,index)=>{
                                return(
                                    <>
                                        <Typography><Checkbox onChange={(e)=>handleSelectItem(index,e.target.checked)} checked={value.is_checked}/>{value.value}</Typography>
                                    </>
                                )
                            }))}
                        </Grid>
                    }
                    {(step===2) &&
                    <>
                        {(message_list.map((value,index)=>{
                            return(
                                <>
                                {(value.is_checked===true) &&
                                <Grid item xs={12} sm={12} md={12} spacing={2}>
                                    <Alert variant="outlined" severity="success" iconMapping={{
    success: <CircularProgressWithLabel value={10} />
  }}>
                                    {/* <CircularProgressWithLabel value={10} /> */} {value.value}
                                    </Alert>
                                </Grid>
                                }
                                </>
                            )
                        }))}
                        
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <TextField
                                label="Enter Username"
                                size="small"
                                fullWidth
                                onChange={(e)=>handleChangeCredentials('username',e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} spacing={2}>
                            <TextField
                                label="Enter Password"
                                size="small"
                                fullWidth
                                type='password'
                                onChange={(e)=>handleChangeCredentials('password',e.target.value)}
                            />
                        </Grid>
                    </>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
            {(step===1) &&
            <>
                <Button onClick={handleClose}>Close</Button>
                <Button variant='contained' color='error' onClick={()=>handleProceed(2)}>Next <KeyboardDoubleArrowRight/></Button>
            </>
            }
            {(step===2) &&
            <>
                <Button onClick={()=>handleProceed(1)}><KeyboardDoubleArrowLeft /> Back</Button>
                <Button variant='contained' color='error' onClick={()=>handleStartNewSession()}>Process <KeyboardDoubleArrowRight/></Button>
            </>
            }
            </DialogActions>
        </Dialog>
    </>
  );
}

export default StartNewSession;